import { defineStore } from "pinia";

const PANEL_DISCUSS = "Discuss";
const PANEL_REPORT = "Report";
const PANEL_CHAT = "Chat";

export const useAppState = defineStore("AppState", {
    state: () => ({
        PANEL_DISCUSS: "Discuss",
        PANEL_REPORT: "Report",
        PANEL_CHAT: "Chat",
        isOpened: true,
        activePanel: PANEL_REPORT,

        isToOpenEditor: false,
        reportBody: "",
        commentBody: "",

        //popup panel state
        isOptionsOpened: false,
        isInvitingUser: false,
        isUserRegistering: false,
        isSettingsOpened: false,
        screenshotType: {
            isSC: null,
            isSV: null,
            isSF: null,
        },
        scrollYPos: null,
        isScreenShotToSendToChat: false,
        isScreenShotSendToOptions: false,
        hasScreenShotStarted: false,
        is_tab_screenshot: true,
        annotateOnImage: null,
        totalCrops: 0,

        uploadedImgUrl: null,
        isUploadImg: true,
        selected_screenshots_ids: [],
    }),

    getters: {
        isClosed: (state) => !state.isOpened,
        isChatActive: (state) => state.activePanel == PANEL_CHAT,
        isDiscussActive: (state) => state.activePanel == PANEL_DISCUSS,
        isReportActive: (state) => state.activePanel == PANEL_REPORT,
        isPopPanelOpened: (state) =>
            state.isOptionsOpened ||
            state.isInvitingUser ||
            state.isUserRegistering ||
            state.isSettingsOpened,
        hasSelectedScreenshot: (state) => state.selected_screenshots_ids.length,
    },

    actions: {
        restoreState() {
            //restore active panel state
            const lastActivePanel = localStorage.getItem("kfu_active_panel");
            this.activePanel = [
                PANEL_CHAT,
                PANEL_DISCUSS,
                PANEL_REPORT,
            ].includes(lastActivePanel)
                ? lastActivePanel
                : PANEL_REPORT;

            //restore widget state(closed/opened)
            localStorage.getItem("kfu_widget_state") == "opened"
                ? this.open()
                : this.close();
        },
        open() {
            this.isOpened = true;
            localStorage.setItem("kfu_widget_state", "opened");
        },
        close() {
            this.isOpened = false;
            localStorage.setItem("kfu_widget_state", "closed");
        },
        openMainPanelWith(activePanel) {
            this.closePopup();
            this.activePanel = activePanel;
            localStorage.setItem("kfu_active_panel", activePanel);
            this.open();
        },
        openChat() {
            this.openMainPanelWith(PANEL_CHAT);
        },
        openDiscuss() {
            this.openMainPanelWith(PANEL_DISCUSS);
        },
        openReport() {
            this.openMainPanelWith(PANEL_REPORT);
        },

        /* popup panel helpers */
        openRegistration() {
            this.isUserRegistering = true;
        },
        openInvitation() {
            this.isInvitingUser = true;
        },
        openOptions() {
            this.isOptionsOpened = true;
        },
        openSettings() {
            this.isSettingsOpened = true;
        },
        closePopup() {
            this.isUserRegistering =
                this.isInvitingUser =
                this.isOptionsOpened =
                this.isSettingsOpened =
                    false;
        },
        getCurrentPageInfo() {
            return {
                domain: window.location.origin,
                page: window.location.pathname,
                url: window.location.href,
                /*others: {
                    hash: window.location.hash,
                    search: window.location.search
                }*/
            };
        },
        scrollBodyToTop(height = 0) {
            document.body.scrollTop = height; // For Safari
            document.documentElement.scrollTop = height; // For Chrome, Firefox, IE and Opera
        },
        storeBodyScrollPositionForRestoringIfActionIsSF() {
            console.log(window.pageYOffset);
            // if (!this.screenshotType.isSF)
            //     return
            // scrollYPos = window.pageYOffset
            // scrollBodyToTop(0)
        },
        openScreenShot(
            isScreenShotSendToOptions = false,
            is_tab_screenshot = false,
        ) {
            this.is_tab_screenshot = is_tab_screenshot;
            this.isScreenShotSendToOptions = isScreenShotSendToOptions;
            this.storeBodyScrollPositionForRestoringIfActionIsSF();
            this.annotateOnImage = null;
            this.hasScreenShotStarted = true;
        },
        openImageForAnnotation(
            imgUrl,
            isToChat = true,
            isScreenShotSendToOptions = false,
        ) {
            this.isScreenShotSendToOptions = isScreenShotSendToOptions;
            this.annotateOnImage = imgUrl;
            this.isScreenShotToSendToChat = isToChat;
            this.hasScreenShotStarted = true;
        },
        cancelScreenShot() {
            console.log("Cancelling screenshot...");
            this.hasScreenShotStarted = false;
            this.annotateOnImage = null;
        },
        onScreenShotAnnotationComplete(imgUrl, targetToSendImage = null) {
            this.uploadedImgUrl = imgUrl;

            if (PANEL_DISCUSS == targetToSendImage) this.newComment();
            else if (PANEL_REPORT == targetToSendImage) this.newReport();
            else if (PANEL_CHAT == targetToSendImage) this.openChat();

            timeOutHelper(() => {
                this.hasScreenShotStarted = false;
            }, 1);
        },
        resetUploadedImgUrl() {
            this.uploadedImgUrl = null;
        },
        incrementTotalCrops() {
            totalCrops += 1;
        },
        disableBodyScroll() {
            document
                .querySelector("body")
                .style.setProperty("overflow", "hidden");
        },
        disableBodyHScroll() {
            const body = document.querySelector("body");
            body.style.setProperty("overflow-x", "hidden");
            body.style.setProperty("display", "block");
        },
        disableBodyVScroll() {
            document
                .querySelector("body")
                .style.setProperty("overflow-y", "hidden");
        },
        enableBodyScroll() {
            const body = document.querySelector("body");
            body.style.setProperty("overflow", "visible");
        },
        setIsUploadImg(val) {
            this.isUploadImg = val;
        },
        setSelectedScreenshot(screenshot_id, reset = false) {
            if (reset) {
                this.selected_screenshots_ids = [];
                return;
            }
            this.selected_screenshots_ids.push(screenshot_id);
        },
        removeSelectedScreenShot(screenshot_id) {
            const finded_ss_idnex = this.selected_screenshots_ids.findIndex(
                (selected_screenshot_id) =>
                    selected_screenshot_id === screenshot_id,
            );

            this.selected_screenshots_ids.splice(finded_ss_idnex, 1);
        },
    },
});
