// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
// import { md1 } from 'vuetify/blueprints';
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

export default createVuetify({
    components,
    directives,
    // blueprint: md1,
    theme: {
        defaultTheme: "myCustomTheme",
        themes: {
            myCustomTheme: {
                dark: false,
                colors: {
                    background: "#FFFFFF",
                    surface: "#FFFFFF",
                    //primary: '#0A2B49',
                    primary: "#36454f",
                    "primary-1": "#0B2B49",
                    "primary-2": "#0e2d45",
                    secondary: "#03DAC6",
                    "secondary-1": "#018786",
                    error: "#B01216",
                    info: "#0079EF",
                    success: "#27ae60",
                    warning: "#F36601",
                    // more colors
                },
                variables: {}, //ADD AN EMPTY OBJECT
            },
        },
    },
    defaults: {
        global: {
            ripple: false,
        },
    },
});
