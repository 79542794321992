import {ref, computed} from 'vue'
import BoardsApi from './boards';
const { fetchBoards,
        processMyWorkspaceBoards,
        processWorkspaceList,
        fetchWorkspaces,
    } = BoardsApi();
import conversationApi from "./conversations";
const { loadMyConversationsWithList,
    loadMyConversations,
    setConversationFlagWith,
    restoreConversationDrafts
} = conversationApi();
//import FireBase from '../Utilities/firebase';
//const { initalizeFireBase } = FireBase()
import User from '../Utilities/User';
const mySelf = ref({});
const authUser= ref(null);
import TwilioClientObserver from '../Utilities/TwilioClientObserver';
const { subscribeWithIdentity } = TwilioClientObserver()
import { openSidePanel, closeSidePanel, setSidePanelCurrentViewWith, setSidePanelCurrentViewWithItems } from './helpers';
import Cache from '../Utilities/Cache';
const cache = new Cache;
const hasUserLoggedIn = ref(true);
const isSignInFormClosed = ref(true);

import axios from 'axios';

export default function Intializer() {
    async function fetchMyDetails() {
        try {
            //window.logTimeWith("fetching my details ")
            const { data: { user, conversations} } = await axios.get('/user');
            //window.logTimeWith("fetched  user and conversations")
            initializeUserAndConversations(user, conversations)
            //initalizeFireBase();
        } catch (error) {
            console.log(error);
            mySelf.value = {}
            //alert("Not able to fetch Logged-in user details", error);
        }
    }
    function initializeUser(user) {
        mySelf.value = user ?? {};
        authUser.value = new User(mySelf.value);
        setTwilioIdentity();
        intializeTwilioClient()
    }
    function initializeUserAndWorkspaces(user, workspaceList) {
        initializeUser(user)
        processWorkspaceList(workspaceList)
    }

    function initializeUserAndConversations(user, conversations) {
        initializeUser(user)
        initializeConversations(conversations)
    }

    function initializeConversations(conversations) {
        loadMyConversationsWithList(conversations);
    }

    function setTwilioIdentity() {
        cache.setTwilioIdentity(loggedInUserChatIdentity());
    }

    function sendRequests() {
        Promise.all([
            axios.get('/user'),
            axios.get("/boards")
        ])
        .then(async ([res1, res2]) => {
            //window.logTimeWith(" processing boards ");
            processWorkspaceList(res1.data.workspaces)
            processMyWorkspaceBoards(res2.data)
            //window.logTimeWith("requests are completed .. process conversations" )
            initializeUserAndConversations(res1.data.user, res1.data.conversations);
            //const boards = await res2.json();
            //console.log( { res1, res2 } );
        })
        .catch(error => {
            console.log(error);
        });
    }

    function setUserCurrentConversationState(is_opened) {
        authUser.value?.setIsConversationOpened(is_opened)
    }

    function setUserContextualListItemId(list_item_id) {
        authUser.value?.setContextualListItemId(list_item_id);
    }
    /* prepare {
            "is_conversation_opened" : 0,
            "opened_list_item_id" : null
        }
    */
    async function updateUserCurrentConversationState(isConvOpened) {
        setUserCurrentConversationState(isConvOpened);
        const data = authUser.value?.getCurrentConversationState();
        const { data: userSettings } = await axios.post('/user/conversation-state', data);
        authUser.value?.setSettings(userSettings)
    }

    async function initialize() {
        //return console.log("not initializing anything unless user selects some worksapce")
        //fetchMyWorkspaces();
        intializeTwilioClient();
        //restoreConversationDrafts();
        restorePanelsStateFromLocalStorage();
        sendRequests();
        //fetchMyDetails();
        //loadMyConversations();
        //fetchBoards();
    }

    function restorePanelsStateFromLocalStorage() {
        // restore conversation state view
        if (cache.isConversationStateValid())
            setConversationFlagWith(cache.getConversationState());
        // restore sidepanel view
        //intializeTwilioClient()
        restoreSidePaenlState()
    }

    function restoreSidePaenlState() {
        const isOpened = cache.getSidePanelState();
        isOpened
            ? openSidePanel()  //setSidePanelCurrentViewWith(sidePanelView)
            : closeSidePanel()
    }

    function loggedInUser() {
        return authUser.value
    }

    function loggedInUserID() {
        return loggedInUser()?.id
    }

    function loggedInUserChatIdentity()
    {
        return authUser.value?.twilioIdentity()
                || cache.getTwilioIdentity()
    }

    function loggedInUserName() {
        return loggedInUser()?.name
    }

    function updateUserFCMRegistrationToken(fcm_token) {
        axios.post('/fcm-token', { fcm_token })
    }

    function saveCurrentState() {
        loggedInUser()?.saveCurrentSession()
    }

    function intializeTwilioClient() {
        const twilioUserIdentity = loggedInUserChatIdentity();
        console.log(` twilio Identity ${twilioUserIdentity}`)
        if( !twilioUserIdentity )
            return "no not possible to create";

        subscribeWithIdentity(twilioUserIdentity)
    }

    function showLoginForm() {
        hasUserLoggedIn.value = false;
        isSignInFormClosed.value = false;
    }

    function hideLoginForm() {
        hasUserLoggedIn.value = true;
        setConversationFlagWith(true);
    }

    function getApiToken() {
        return cache.getApiToken()
    }

    function getWorkspaceKey() {
        return prospus_data['workspace_key']
    }

    async function loginWith(userDetails) {
        const { data } =  await axios.post("/login", userDetails)
        cache.setApiToken(data.access_token);
        hideLoginForm();
        initialize();
    }

    function closeSignInForm() {
        isSignInFormClosed.value = true;
    }

    function loadBoardsAndConversationsFordWorksapce(workspace) {
        const workspaceQueryString = `?workspace=${workspace.workspace_id}`

        window.registerForWorspaceUpdates(workspace.workspace_id);

        Promise.all([
                axios.get(`/conversations${workspaceQueryString}`),
                axios.get(`/boards${workspaceQueryString}`)
            ])
            .then(async ([res1, res2]) => {
                initializeConversations(res1.data)
                processMyWorkspaceBoards(res2.data)
                //console.log( { res1:  res1.data, res2 : res2.data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    return {
        initializeUserAndWorkspaces,
        loadBoardsAndConversationsFordWorksapce,
        initialize,
        loggedInUser,
        loggedInUserID,
        loggedInUserChatIdentity,
        loggedInUserName,
        setUserCurrentConversationState,
        setUserContextualListItemId,
        updateUserCurrentConversationState,
        updateUserFCMRegistrationToken,
        intializeTwilioClient,
        saveCurrentState,

        hasUserLoggedIn: computed(() => hasUserLoggedIn.value),
        hasUserNotLoggedIn: computed(() => !hasUserLoggedIn.value),
        isSignInFormClosed: computed(() => isSignInFormClosed.value),
        showLoginForm,
        hideLoginForm,
        getApiToken,
        loginWith,
        closeSignInForm,
        getWorkspaceKey,

        restoreSidePaenlState
    }
}
