import axios from 'axios';
import {ref} from 'vue';
import Cache from './Cache';
import Utilities from '../Helpers/utilities'
import {useConversation} from '../Stores/Conversation';

// import conversationApi from '../composition-api/conversations'
function loadTokenForConversation() {
    const token = window.profile?.token_details?.token;
    // console.log("user token ", token)
    return token;
}

function newInvitationReceivedOnConversation() {

}

const userIdentityTwilioClientMap = ref({
    /* "identity": {
          access_token:'',
          twilio_client: '',
          subscribers: [], // Conversation objects
          isInProcess: true
      } */
});

const conv_sid_map = new Map();

export default function TwilioFactory() {
    async function createNewAccessToken(userIdentity) {

        const cache = new Cache();
        const {data} = await axios.get("/access-token")

        cache.setToken(userIdentity, data)

        return cache.getToken(userIdentity);
    }

    async function fetchAccessToken(userIdentity) {

        const cache = new Cache();
        let chat_access_token = cache.hasValidToken(userIdentity);
        if (chat_access_token)
            return chat_access_token;

        return await createNewAccessToken(userIdentity)
    }

    async function createClientForIdentity(userIdentity, is_to_reset = false) {
        const tokenDetails = userIdentityTwilioClientMap.value[userIdentity];
        /* if Twilio client already created, then don't do anything */
        if (is_to_reset == false && tokenDetails.twilio_client) return;
        // window.logTimeWith("creating twilio client ")
        const accessToken = await fetchAccessToken(userIdentity);

        if (!accessToken)
            return resetForIdentity(userIdentity)


        tokenDetails.access_token = accessToken;

        const twilioClient = new Twilio.Conversations.Client(accessToken/*, {logLevel: 'debug'}*/);
        tokenDetails.twilio_client = twilioClient;
        twilioClient.on('initialized', () => {
            //console.log("Twillio client is initialized")
            setTimeout(() => prepareSubscribedConversastions(userIdentity)/*  updateSubscribersForIdentity(userIdentity, twilioClient) */, 1000)
            registerTwilioClientForEvents(twilioClient, userIdentity);
        });
        twilioClient.on('initFailed', (errorDetail) => {
            useConversation().twilioConnectingStarted();
            if (isTwilioStateConnected(twilioClient))
                useConversation().connected()
            else
                useConversation().disconnected();
            console.log('initFailed: ', errorDetail);
        });

    }

    function registerTwilioClientForEvents(twilioClient, userIdentity) {
        // console.log("registerTwilioClientForEvents  " + userIdentity)
        /* register for all events */
        twilioClient.on('connectionStateChanged', (state) => {
            console.log(`connectionStateChanged : ${state}`)

            if (state === 'connecting') {
                useConversation().twilioConnectingStarted();
            } else {
                useConversation().resetTwilioConnectingStarted();
            }

            //return;
            if (state === 'connecting') updateSubscribersStateToConnecting(userIdentity);
            else if (state === 'connected') updateSubscribersStateToConnected(userIdentity);
            else if (state === 'disconnecting' || state === 'disconnected') updateSubscribersStateToOffline(userIdentity);
            else if (state === 'denied') updateSubscribersStateToFailed(userIdentity);
        });

        twilioClient.on('connectionError', (errorDetail) => {
            console.log('connectionError: ', errorDetail);
            // if (errorDetail.terminal)
            //     updateSubscribersStateToOffline(userIdentity)
            useConversation().disconnected()
        });

        twilioClient.on("conversationAdded", (conversation) => {
            //console.log('conversationAdded');
            updateSubscribersStateToConnected()
            //updateSubscribersConversation(userIdentity, conversation);
        })

        twilioClient.on('conversationJoined', (conversation) => {
            // conversations = [...conversations, conversation];
            // loadCurrentConversation();
        });
        twilioClient.on('conversationLeft', (thisConversation) => {
            // conversations = [...conversations.filter((it) => it !== thisConversation)]
        });

        twilioClient.on('tokenAboutToExpire', () => {
            console.log('tokenAboutToExpire: client token is expiring');
            //prepareSubscribedConversastions(userIdentity)
            //updateClientTokenForIdentity(userIdentity)
        });

        twilioClient.on("tokenExpired", () => {
            console.log("token expired")


            //displayNotification('Twilio token expired. Creating new...', 1000*60*60)
            createClientForIdentity(userIdentity, true)
        });

        twilioClient.on('messageAdded', (message) => {
            const conv = message.conversation
            //console.log('message added to conv: ', conv.sid, conv.friendlyName)
            loadConversationOnNewMessageIfConversationNotLoaded(userIdentity, conv)
        })

    }

    function prepareConversationMap(user_identity) {
        const identity_details = userIdentityTwilioClientMap.value[user_identity];


        identity_details
            ?.subscribers
            ?.forEach((convObj) => conv_sid_map.set(convObj.sid, convObj));
    }

    async function loadConversationOnNewMessageIfConversationNotLoaded(user_identity, conv) {
        const conv_sid = conv.sid

        if (!conv_sid_map.size)
            prepareConversationMap(user_identity)

        if (conv_sid_map.has(conv_sid))
            return;

        conv_sid_map.set(conv_sid, true)

        try {
            // console.log('message added to conv not found in loaded list: ', conv.sid, conv.friendlyName)
            await useConversation().loadConversationBySID(conv_sid)
        } catch (error) {
            console.log(`conversation ${conv_sid} not loaded, clearing from map`)
            conv_sid_map.delete(conv_sid)
        }
    }

    async function updateClientTokenForIdentity(userIdentity) {
        displayNotification()

        const token_details = userIdentityTwilioClientMap.value[userIdentity];

        const twilio_client = token_details?.twilio_client

        if (twilio_client) {
            const token = await createNewAccessToken(userIdentity)
            token_details.twilio_client = await twilio_client.updateToken(token)
            updateSubscribersForIdentity(userIdentity, token_details.twilio_client)
            return;
        }

        console.log("updateClientTokenForIdentity : token details not found , creating new for identity ", userIdentity)
        createClientForIdentity(userIdentity, true)
    }

    function displayNotification(message, timeout = 3000) {
        try {
            const {toggleSnackBarOff, toggleSnackBarOn, setSnackbar} = Utilities()
            toggleSnackBarOff()
            setSnackbar({
                message,
                timeout,
                visible: false,
                color: 'error',
                location: 'top center',
            });
            toggleSnackBarOn()
        } catch (err) {
            console.log("error while displaying notificaiton when twilio token expired")
        }
    }

    function resetForIdentity(userIdentity) {
        delete userIdentityTwilioClientMap.value[userIdentity];
    }

    function getTokenDetails(userIdentity, conversation_id) {
        let tokenDetails = userIdentityTwilioClientMap.value[userIdentity];
        if (tokenDetails) return tokenDetails;
        tokenDetails = {
            twilio_client: null,
            subscribers: [],
            isInProcess: true,
        };
        userIdentityTwilioClientMap.value[userIdentity] = tokenDetails;
        createClientForIdentity(userIdentity);
        return userIdentityTwilioClientMap.value[userIdentity];
    }

    function subscribeWithIdentity(userIdentity, conObj = null) {
        if (!userIdentity) return console.log("userIdentity is empty, Twilio Client can't be created");
        const accessTokenDetail = getTokenDetails(userIdentity, conObj?.id);
        if (!conObj) return;
        accessTokenDetail.subscribers.push(conObj);

        if (!conObj.isPaginated()) {
            fetchConversation(accessTokenDetail?.twilio_client, conObj);
        }
    }


    function unregister(userIdentity, conObjToUnregister) {
        const accessTokenDetail = userIdentityTwilioClientMap.value[userIdentity];
        accessTokenDetail.subscribers = accessTokenDetail.subscribers.filter((convObj) => convObj.id !== conObjToUnregister.id);
    }

    function updateSubscribersStateToConnecting(userIdentity) {
        notifySubscribers(userIdentity, (convObj) => convObj.setStatusToConnecting())
        //useConversation().connected()
    }

    function updateSubscribersStateToConnected(userIdentity) {
        prepareSubscribedConversastions(userIdentity)
        notifySubscribers(userIdentity, (convObj) => convObj.setStatusToConnected())
        useConversation().connected()
    }


    function updateSubscribersStateToFailed(userIdentity) {
        useConversation().disconnected()
        notifySubscribers(userIdentity, (convObj) => convObj.setStatusToFailed());
    }

    function updateSubscribersStateToOffline(userIdentity) {
        useConversation().disconnected()
        notifySubscribers(userIdentity, (convObj) => convObj.setStatusToOffline());
    }

    function notifySubscribers(userIdentity, callBack) {
        const accessTokenDetail = userIdentityTwilioClientMap.value[userIdentity];
        accessTokenDetail?.subscribers.forEach((convObj) => callBack(convObj));
    }

    function updateSubscribersState(userIdentity, state) {
        const accessTokenDetail = userIdentityTwilioClientMap.value[userIdentity];
        accessTokenDetail?.subscribers.forEach((convObj) => convObj.updateState(state));
    }

    function updateSubscribersConversation(userIdentity, twilioConversation) {
        const accessTokenDetail = userIdentityTwilioClientMap.value[userIdentity];
        // const twilioClient = accessTokenDetail.twilio_client;
        /* here we can find convObj based on SID, and set that */
        const conObjUpdated = accessTokenDetail
            .subscribers
            .find((convObj) => {
                if (twilioConversation.sid !== convObj.sid) return false;
                convObj.onConversationFound(twilioConversation);
                return true;
            });

        if (conObjUpdated) return;
        // check if time diff is more than 1 minit, then it's already created
        const timeDiffInMillSeconds = new Date() - twilioConversation.dateCreated;
        const createdTimeDiffInMints = Math.floor(timeDiffInMillSeconds / 60000);

        if (createdTimeDiffInMints > 1) return;

        newInvitationReceivedOnConversation(twilioConversation);
    }

    function updateSubscribersForIdentity(userIdentity, twilio_client) {
        useConversation().connected();
        const accessTokenDetail = userIdentityTwilioClientMap.value[userIdentity];
        accessTokenDetail.subscribers?.forEach((convObj) => {
            fetchConversation(twilio_client, convObj);
        });
    }

    async function prepareSubscribedConversastions(user_identity) {
        prepareConversationMap(user_identity)

        const token_details = userIdentityTwilioClientMap.value[user_identity]

        const twilio_client = token_details?.twilio_client

        if (!twilio_client)
            return //console.log("twilio client is invalid or not set")

        let subscriptions = await twilio_client.getSubscribedConversations()

        mapConversationsToToHandlers(subscriptions.items)
        while (subscriptions.hasNextPage) {
            subscriptions = await subscriptions.nextPage()
            mapConversationsToToHandlers(subscriptions.items)
        }
    }

    function mapConversationsToToHandlers(twilio_convs) {
        twilio_convs
            .forEach(twilio_conv => {
                const conv_handler = conv_sid_map.get(twilio_conv.sid)

                conv_handler?.onConversationFound(twilio_conv);
            })
    }

    async function fetchConversation(twilioClient, convObj) {
        if (!twilioClient || convObj.hasTwilioConversationSet()) return;
        // window.logTimeWith(`fetchConversation: ${convObj.sid}`)
        twilioClient
            .getConversationBySid(convObj.sid)
            // .getConversationByUniqueName(chatDetails.unique_name)
            .then((conversation) => {
                convObj.onConversationFound(conversation);
            })
            .catch((err) => {
                convObj.setInitialMessagesFetched()
                console.log('error while finding the conversatio with id ', {
                    sid: convObj.sid,
                    name: convObj.getName(),
                    err
                })
            });
    }

    function isTwilioConnected() {
        const first_identity_object = Object.values(userIdentityTwilioClientMap.value ?? {})[0]
        const twilio_client = first_identity_object?.twilio_client
        //console.log("twilio client ", twilio_client)
        return isTwilioStateConnected(twilio_client)
    }

    function isTwilioStateConnected(twilio_client) {
        return twilio_client?.connectionState === 'connected'
    }

    function getTwilioState() {
        const first_identity_object = Object.values(userIdentityTwilioClientMap.value ?? {})[0]
        return first_identity_object?.twilio_client?.connectionState
    }

    return {
        subscribeWithIdentity,
        isTwilioConnected,
        getTwilioState
    };
}
