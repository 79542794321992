// import ConversationApi from './composition-api/conversations';
// const { makeCurrentConversationAsAbsent, makeCurrentConversationAsActive } = ConversationApi()
/*
window.throttle = (func, limit) => {
    let lastFunc
    let lastRan
    return function () {
        const context = this
        const args = arguments
        if (!lastRan) {
            func.apply(context, args)
            lastRan = Date.now()
        } else {
            clearTimeout(lastFunc)
            lastFunc = setTimeout(function () {
                if ((Date.now() - lastRan) >= limit) {
                    func.apply(context, args)
                    lastRan = Date.now()
                }
            }, limit - (Date.now() - lastRan))
        }
    }
} */
import {debounce} from 'lodash'
import {useAppState} from "../Stores/AppState";
import {useConversation} from "../Stores/Conversation";

window.throttle = (callback, time_lapse = 1000) => debounce(callback, time_lapse)

window.logTimeWith = function (message) {
    const dateString = new Date();
    const currentTimeStamp = `${dateString.toLocaleDateString()}-${dateString.getHours()}:${dateString.getMinutes()}:${dateString.getSeconds()}:${dateString.getMilliseconds()}`;

    console.log(message, currentTimeStamp);
};

window.sleepAsync = (milliseconds) => new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
});

window.sleep = function (milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
};

window.sleepFor = (seconds) => new Promise((res) => {
    setTimeout(res, 1000 * seconds);
});

window.removeUpdateWarningMessages = () => {
    let updateWarningContainer = document.getElementsByClassName('update-nag');
    if (!updateWarningContainer.length) updateWarningContainer = document.getElementsByClassName('notice');
    if (!updateWarningContainer.length) updateWarningContainer = document.getElementsByClassName('notice-warning');

    // updateWarningContainer.length && updateWarningContainer[0]?.remove();
};

window.randomString = (maxDigits = 18) => {
    const newMaxDigits = maxDigits > 18 ? 18 : maxDigits;
    return (Math.random()).toLocaleString('en-US', {maximumSignificantDigits: newMaxDigits}).split('.')[1];
};

window.replaceLinksWithATags = (text) => {
    return text;

    const newText = text.trim();
    if (newText.startsWith('<iframe')) {
        return newText;
    }

    /* eslint-disable-next-line */
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return newText.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
};

window.removeEmptyTags = (html, exceptions = null) => {
    return html.replace(/<[^/>][^>]*><\/[^>]+>/gim, exceptions ? (match, p1, offset) => {

        let has_exception = false

        exceptions.forEach(item => {
            if (match.toLowerCase().startsWith('<' + item)) {
                has_exception = true
                return
            }
        })

        if (has_exception)
            return match
        else
            return ''
    } : '')
}

window.replacingMessageTags = (html) => {
    return html.replace(/<[^/>][^>]*><\/[^>]+>/gim, (match, p1, offset) => {
        let div = document.createElement('div')
        div.innerHTML = match

        if (match.toLowerCase().startsWith('<file')) {
            const tag_node = div.firstChild
            const dom = window.convertHTMLFIleTag({
                attrs: {
                    fileName: tag_node.attributes?.fileName?.value,
                    fileSize: tag_node.attributes?.fileSize?.value,
                    fileType: tag_node.attributes?.fileType?.value,
                    src: tag_node.attributes?.src?.value
                }
            })

            return dom.outerHTML
        } else if (match.toLowerCase().startsWith('<video')) {
            const tag_node = div.firstChild
            const dom = window.convertHTMLVideoTag({
                attrs: {
                    type: tag_node.attributes?.type?.value, src: tag_node.attributes?.src?.value
                }
            })

            return dom.outerHTML
        }
    })
}

window.convertHTMLFIleTag = (node) => {
    const container = document.createElement('div');
    const link = document.createElement('a')
    const icon = document.createElement('span')
    const span = document.createElement('span')
    const ul = document.createElement('ul')
    const fileName = document.createElement('li')
    const fileSize = document.createElement('li')
    const fileType = document.createElement('li')

    link.href = node.attrs.src
    link.target = '_blank'

    link.classList.add('file')
    icon.classList.add('icon', 'material-symbols-rounded')
    icon.append('file_present')

    fileName.append(`Name: ${node.attrs.fileName}`)
    fileSize.append(`Size: ${window.formatBytes(node.attrs.fileSize, 0)}`)
    fileType.append(`Type: ${node.attrs.fileType}`)

    ul.append(fileSize)
    ul.append(fileType)
    ul.append(fileName)

    span.append(ul)
    link.append(icon)
    link.append(span)
    container.append(link)

    return container
}

window.convertHTMLVideoTag = (node) => {
    const video = document.createElement('video')
    const source = document.createElement('source')
    source.src = node.attrs.src
    source.type = node.attrs.type
    video.controls = true
    video.append(source)

    return video
}

window.formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

window.stripHtmlTags = html => {
    if (!html) return

    const regex = /(<([^>]+)>)/gi;
    const plainText = html.replace(regex, (match, p1, offset) => {
        if (p1.startsWith('<a')) {
            const hrefRegex = /href="([^">]+)"/i;
            const hrefMatch = hrefRegex.exec(p1);
            if (hrefMatch) {
                return hrefMatch[1] + ' ';
            }
        } else if (p1.startsWith('<img')) {
            const srcRegex = /src="([^">]+)"/i;
            const srcMatch = srcRegex.exec(p1);
            if (srcMatch) {
                return srcMatch[1] + ' ';
            }
        }
        return ' ';
    }).trim();

    return plainText;
}

window.stripHtmlListTags = (html) => {
    const regex = /(<(?!\/?(ul|ol|li)\b)[^>]+>|<ul[^>]*>|<\/ul>|<ol[^>]*>|<\/ol>|<li[^>]*>|<\/li>)/gi; // regular expression to match HTML tags
    return html.replace(regex, (match) => {
        if (match.toLowerCase().startsWith('<ul') || match.toLowerCase().startsWith('<ol') || match.toLowerCase().startsWith('<li') || match.toLowerCase().startsWith('<p') || match.toLowerCase().startsWith('<div')) {
            return match.replace(/\s+\S+(['"])\S+(['"])/g, '');
        } else if (match.toLowerCase().startsWith('<img') || match.toLowerCase().startsWith('</ul') || match.toLowerCase().startsWith('</ol') || match.toLowerCase().startsWith('</li') || match.toLowerCase().startsWith('</p') || match.toLowerCase().startsWith('</div') || match.toLowerCase().startsWith('<br')) {
            return match
        } else return ''

    });
}

// const regex = /(<(?!\/?(ul|ol|li)\b)[^>]+>|<ul[^>]*>|<\/ul>|<ol[^>]*>|<\/ol>|<li[^>]*>|<\/li>)/gi; // regular expression to match HTML tags except for ul, ol, and li
// let strippedText = html.replace(regex, (match) => {
//   if (match.toLowerCase().startsWith('<ul') || match.toLowerCase().startsWith('<ol') || match.toLowerCase().startsWith('<li')) {
//     return match.replace(/\s+\S+(['"])\S+(['"])/g, ''); // remove all attributes from ul, ol, and li tags
//   } else {
//     return '';
//   }
// });

// return strippedText;

window.truncateText = (str, length = 45) => {
    if (!str) return

    return str.length > length ? str.substring(0, length) + "..." : str;
}

/**
 *
 * this causes links without any http/https/www to append to the current url
 * for ex: glassdoor.co.in/member/profile/stepWelcome.htm -
 * link like this will be appended to location.href
 */

// function validURLRegex(myURL) {
//   return new RegExp('^(https?:\\/\\/)?' // protocol
//         + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' // domain name
//         + '((\\d{1,3}\\.){3}\\d{1,3}))' // ip (v4) address
//         + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port
//         + '(\\?[;&amp;a-z\\d%_.~+=-]*)?' // query string
//         + '(\\#[-a-z\\d_]*)?$', 'i');
// }

window.isArrayEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
        if (a[i].id !== b[i].id) return false;
    }
    return true;
}

function onFocused() {
    // console.log( "focused ... ");
    window.isAppInActive = false;
    // makeCurrentConversationAsActive();
    useAppState().setInActiveWindow(window.isAppInActive)
    useConversation().updateConnectionStatusIfNotConnected()
}

function onBlurred() {
    // console.log(" blurred ... ");
    window.isAppInActive = true;
    // makeCurrentConversationAsAbsent();
    useAppState().setInActiveWindow(window.isAppInActive)
}

window.addEventListener('focus', onFocused);

// Inactive
window.addEventListener('blur', onBlurred);

document.addEventListener('visibilitychange', () => {
    window.isAppInActive = document.visibilityState === 'hidden';
    useAppState().setInActiveWindow(window.isAppInActive)
});

window.timeOutHelper = (call_back, time_in_milli_seconds = 100) => {

    const handler = setTimeout(() => {
        call_back()
        clearTimeout(handler)
    }, time_in_milli_seconds)
}

window.scrollTo = (id) => {
    document.querySelector('#' + id)?.scrollIntoView()
}


window.addEventListener('online', handleOnline);
window.addEventListener('offline', handleOffline);

function handleOnline() {
    useAppState().setInternetConnectivity(true)
}

function handleOffline() {
    useAppState().setInternetConnectivity(false)
}
