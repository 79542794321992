import { defineStore } from "pinia";
import { useWorkspace } from "./Workspaces";

export const useWorkspaceDnd = defineStore("workspace_dnd", {
    state: () => {
        return {
            kfu_bo_item_dragging: false,
            is_dragging_started: false,
            is_item_dragging_in_progress: false,
            dragged_item: null,
            dragged_item_parent: null,
            dragged_item_location: null,
            auto_scrollable_hover_zone: null,
            selected_items: [],
            drag_object_event: {},
            skip_canceling_multi_select: false,
            last_item_selected: null,
            multi_select_started: false,
        };
    },
    getters: {},
    actions: {
        toggleAutoScrollHoverZone(value = null) {
            if (value === null) return;

            this.auto_scrollable_hover_zone = value;
        },
        startDraggingItem() {
            this.is_item_dragging_in_progress = true;
        },
        stopDraggingItem() {
            this.is_item_dragging_in_progress = false;
        },
        setDraggedItemParent(value) {
            this.dragged_item_parent = value;
        },
        setDraggedItem(value) {
            this.dragged_item = value;
        },
        setDraggedItemLocation(value) {
            this.dragged_item_location = value;
        },
        startDrag(
            element = ".dragged-element",
            parent_element = ".prospus-workbase-body",
        ) {
            this.is_dragging_started = true;
            localStorage.setItem("kfu_bo_item_dragging", "dragging");

            const el = document.querySelector(element);
            const newParent = document.querySelector(parent_element);

            if (el) {
                newParent.appendChild(el);
            }
        },
        endDrag(evt) {
            this.is_dragging_started = false;
            localStorage.setItem("kfu_bo_item_dragging", false);
        },
        resizeDropzone(
            class_name = "dndrop-drop-preview-default-class",
            value = null,
        ) {
            let element = document.getElementsByClassName(class_name)[0];

            if (value && element) {
                element.style.height = `${value}px`;
                element.style.background = `#dddddd`;
                element.style.border = "none";
            }
        },

        resizeDropzoneWrapper(
            class_name = "dndrop-drop-preview-constant-class",
            value = null,
        ) {
            let element = document.querySelector(`.${class_name}`);

            if (value && element) element.style.height = `${value}px`;
        },

        addSelectedItem(val, force_push = false) {
            if (!this.unselectItem(val) || force_push)
                this.selected_items.push(val);
        },

        setSelectedItem(val) {
            if (!this.unselectItem(val) || this.selected_items.length)
                this.selected_items = [val];
            else this.selected_items = [];
        },

        setArraySelectedItem(val) {
            this.selected_items = val;
        },

        clearSelectedItems(val = null) {
            this.selected_items = val ? [val] : [];
        },

        //Dont remove for now, its for reference.
        // clearSelectedItemsByCat( cat_id ) {
        //     let indexes = []
        //     this.selected_items.forEach( (item, index) => {
        //         if (item.item_category_id === cat_id)
        //             indexes.push(index)
        //     })

        //     indexes = indexes.reverse()
        //     indexes.forEach(index => {
        //         this.selected_items.splice(index, 1)
        //     })
        // },

        isSelectedItem(val) {
            return this.selected_items.findIndex((item) => {
                return item.id === val.id;
            });
        },

        unselectItem(val) {
            console.log("unselect called");
            const index = this.isSelectedItem(val);

            if (index < 0) {
                return false;
            }

            const workspace_store = useWorkspace();
            this.selected_items.splice(index, 1);
            workspace_store.setActiveItem(null);

            return true;
        },

        dragObjectEvent(event) {
            this.drag_object_event = event;
        },

        continueMultiSelect() {
            this.skip_canceling_multi_select = false;
        },

        skipMultiSelect() {
            this.skip_canceling_multi_select = true;
        },

        cancelMultiDrag() {
            const workspace_store = useWorkspace();

            if (this.is_dragging_started) {
                try {
                    this.drag_object_event?.cancelDrag();
                } catch (e) {
                    console.error("cancelMultiDrag error");
                }
            }

            this.endDrag();
            this.clearSelectedItems();
            this.clearLastItemSelected();
            workspace_store.setActiveItem(null);
            this.multi_select_started = false;
        },

        setLastItemSelected(id, index, item) {
            this.last_item_selected = { cat_id: id, index: index, item: item };
        },

        clearLastItemSelected() {
            this.last_item_selected = null;
        },

        startMultiSelect() {
            this.multi_select_started = true;
        },

        stopMultiSelect() {
            this.multi_select_started = false;
        },
    },
});
