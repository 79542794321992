import axios from "axios";
import { defineStore } from "pinia";
import { computed, inject } from "vue";
import { de, tr } from "vuetify/locale";
import { useWorkspace } from "./Workspaces";
import { useUserStore } from "./User";
import Utilities from "../Helpers/utilities";
import DateUtilities from "../Utilities/DateUtilities";
import { useAppState } from "./AppState";
import { useConversation } from "./Conversation";
import HttpStatusCodes from "../Constants/http";

// const takeUserConfirmation = inject('takeUserConfirmation')

export const useTimeTrackerStore = defineStore("timeTrackerStore", {
    state: () => {
        return {
            Shift_In: "Shift_In",
            Shift_Pause: "Shift_Pause",
            Shift_Resume: "Shift_Resume",
            Shift_Out: "Shift_Out",

            Start_Stop: "Start_Stop",
            Pause_Resume: "Pause_Resume",

            Task_Start: "Task_Start",
            Task_Stop: "Task_Stop",
            Task_Resume: "Task_Resume",
            Task_Pause: "Task_Pause",

            Workday_Started: "Workday_Started",
            Workday_Stopped: "Workday_Stopped",

            workday_status: null,
            can_edit_workday: false,

            timer_item_id: null,
            timer_item_name: null,
            current_task: {
                item_id: 1,
                seconds: 10,
            },
            timer: {
                id: null,
                name: null,
            },
            task: {
                name: null,
                id: null,
                status: "Task_Stop",
                duration: 0,
                time: {
                    hours: 0,
                    seconds: 0,
                    minutes: 0,
                },
            },

            handle_shift_timer_interval: null,
            shift: {
                time: {
                    seconds: 0,
                    minutes: 0,
                    hours: 0,
                },
                status: null,
                duration: 0,
            },
            shift_time: {
                seconds: 0,
                minutes: 0,
                hours: 0,
            },
            task_time: {
                seconds: 0,
                minutes: 0,
                hours: 0,
            },
            space_paused_items: [],
            is_space_paused_items: false,

            shift_status: null,
            shift_timer: null,
            restore_working_duration: null,
            restore_last_shift_event: null,
            restore_task_duration: null,
            restore_last_lask_event: null, // is_shift_paused: null,
            shiftTimeout: null,

            handle_task_timer_interval: null,
            task_timer: null,
            task_status: null,
            click_task_item: null,
            click_task_item_status: null,

            is_prepared: false,
            is_auto_paused: false,
            viewed_task: {
                id: null,
                duration: null,
            },
            ws_confirm_dialog: false,
        };
    },

    getters: {
        is_shift_in: (state) => state.shift.status == state.Shift_In,
        is_shift_resumed: (state) => state.shift.status == state.Shift_Resume,
        is_shift_paused: (state) => state.shift.status == state.Shift_Pause,
        is_shift_out: (state) => state.shift.status == state.Shift_Out,
        has_shift_started: (state) =>
            state.is_shift_resumed || state.is_shift_in,
        is_to_show_resume_and_stop: (state) =>
            state.has_shift_started || state.is_shift_paused,

        is_task_started: (state) => state.task.status == state.Task_Start,
        is_task_resumed: (state) => state.task.status == state.Task_Resume,
        is_task_paused: (state) => state.task.status == state.Task_Pause,
        is_task_stopped: (state) => state.task.status == state.Task_Stop,
        is_display_task_tracker: (state) =>
            (state.is_shift_in ||
                state.is_shift_resumed ||
                state.is_shift_paused) &&
            state.task.status &&
            !state.is_task_stopped &&
            state.is_workday_started,
        is_show_resume_and_stop_workday: (state) =>
            state.workday_status !== state.Workday_Stopped,
        is_workday_started: (state) =>
            state.workday_status == state.Workday_Started && state.is_prepared,
        is_show_workday_stopped: (state) =>
            state.workday_status == state.Workday_Stopped,
        is_workday_pause: (state) => false,

        timer_paused_task_items_url: (state) =>
            "/shift-timer/shift/paused-task-items",
        timer_shift_log_url: (state) => "/shift-timer/shift/log",
        timer_shift_work_log_url: (state) => "/shift-timer/shift/work-log",
        timer_shift_status_url: (state) => "/shift-timer/shift/status",
        timer_task_log_url: (state) => "/shift-timer/task/play",
        timer_log_sync_url: (state) => "/shift-timer/shift/status/sync",

        workday_start_url: (state) => "/workdays/start",
        workday_end_url: (state) => "/workdays/end",

        //shift_time_in_minutes : (state) => Math.floor(state.shift_time_in_seconds/60),
    },
    actions: {
        startShiftTimer() {
            this.handle_shift_timer_interval = setInterval(() => {
                this.shift.time.seconds += 1;
                if (this.shift.time.seconds == 60) {
                    this.shift.time.seconds = 0;
                    this.shift.time.minutes += 1;
                    if (this.shift.time.minutes == 60) {
                        this.shift.time.minutes = 0;
                        this.shift.time.hours = this.shift.time.hours + 1;
                    }
                    this.shift.time.hours = Math.floor(
                        this.getShiftTimerDuration() / 60,
                    );
                    // call api on every 3 minutes
                    if (this.shift.time.minutes % 3 === 0) {
                        timeOutHelper(() => {
                            this.syncTaskTimer();
                        }, 0);
                    }
                }
            }, 1000);

            if (this.is_task_started || this.is_task_paused)
                this.startTaskTimer();
        },

        restoreShiftTimer(time) {
            var resultInMinutes = this.shift.duration;

            var hours = resultInMinutes / 60;
            var rhours = Math.floor(hours);

            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);

            var secunds = (minutes / 60) * 60;
            var rsecunds = Math.round(secunds);

            this.shift.time.hours = rhours;
            this.shift.time.minutes = rminutes;
            this.shift.time.seconds = rsecunds;
        },

        restoreShiftStatus(data) {
            this.stopShiftTimer();
            this.workday_status = data?.workday_status;
            this.can_edit_workday = data?.can_edit_workday;

            this.shift.duration = data?.shift_duration_in_minutes;
            this.shift.status = data?.shift_status;

            //if (data?.task_item_status != this.Task_Stop) {
                this.task.id = data?.task_item_id ?? null;
                this.task.name = data?.task_item_name ?? null;

                this.task.duration = data?.task_duration_in_minutes ?? 0;
                this.task.status = data?.task_item_status ?? this.Task_Stop;
            //}
            this.restoreShiftTimer();

            if (this.is_shift_resumed) {
                this.startShiftTimer();
                this.shift.status = this.Shift_Resume;
            }

            if (this.is_shift_paused) {
                this.stopShiftTimer();
                this.shift.status = this.Shift_Pause;
            }

            if (this.is_shift_in) {
                this.startShiftTimer();
                this.shift.status = this.Shift_In;
            }

            if (
                data?.task_duration_in_minutes ||
                this.task.status === this.Task_Start
            )
                this.restoreTaskStatus();

            this.is_prepared = true;
        },

        stopShiftTimer() {
            this.stopTaskTimer();
            clearInterval(this.handle_shift_timer_interval);
        },

        resetShiftTimer() {
            this.stopShiftTimer();
            this.shift.time = {
                seconds: 0,
                minutes: 0,
                hours: 0,
            };
        },

        setSpacePausedItems(spacePausedItems) {
            this.space_paused_items = spacePausedItems;
            this.is_space_paused_items = true;
        },

        async getPausedItems() {
            await axios
                .post(this.timer_paused_task_items_url)
                .then(({ data }) => {
                    this.setSpacePausedItems(data);
                    this.restoreLastPauseTask();
                });
        },

        async getShiftStatus() {
            const { data } = await axios.post(this.timer_shift_status_url);
            this.restoreShiftStatus(data);
        },

        getShiftTimerDuration() {
            return this.shift.time.minutes + this.shift.time.hours * 60;
        },

        getTaskTimerDuration() {
            return this.task.time.minutes + this.task.time.hours * 60;
        },

        getActiveTimerStatus() {
            return [this.Shift_In, this.Shift_Resume];
        },

        getShiftEvents() {
            return [
                this.Shift_In,
                this.Shift_Resume,
                this.Shift_Pause,
                this.Shift_Out,
            ];
        },

        setTaskNameInTitle(text, name) {
            text = text.replace("{title}", "<b>" + name + "</b>");
            text = text.replace(
                "{current_task_title}",
                "<b>" + this.task.name + "</b>",
            );
            return text;
        },

        restoreTaskTimer() {
            var resultInMinutes = this.task.duration;

            var hours = resultInMinutes / 60;
            var rhours = Math.floor(hours);

            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);

            var secunds = (minutes / 60) * 60;
            var rsecunds = Math.round(secunds);

            this.task.time.hours = rhours;
            this.task.time.minutes = rminutes;
            this.task.time.seconds = rsecunds;
        },

        restoreTaskStatus() {
            this.restoreTaskTimer();

            if (this.is_task_started) {
                this.startTaskTimer();
                this.task.status = this.Task_Start;
                return;
            }
            //
            if (this.is_task_paused) {
                this.stopTaskTimer();
                this.task.status = this.Task_Pause;
            }

            if (this.is_task_resumed) {
                this.startTaskTimer();
                this.task.status = this.Task_Resume;
            }

            if (this.is_task_stopped) {
                this.stopTaskTimer();
                this.task.status = this.Task_Stop;
            }
        },

        async shiftIn(item = null) {
            if (this.isForceUseAppForShiftStart()) {
                this.showWorkstationRequireDialog();
                return;
            }

            this.shift.status = this.Shift_In;

            try {
                const { data } = await axios.post(this.timer_shift_log_url, {
                    event: this.Shift_In,
                });

                this.shift.status = data?.action

                this.startShiftTimer();
                if (this.is_task_started) {
                    this.startTaskTimer();
                }

                this.restoreLastPauseTask();

                //this.sendEventToConversationEvents(data?.action, item);
            } catch (e) {
                this.getShiftStatus();
                Utilities().showErrorSnackbar(e?.response?.data?.message);
                console.error("Shift In failed");
            }
        },

        async shiftPause() {
            if (!this.is_shift_in && !this.is_shift_resumed) return;

            // pause shift
            this.stopShiftTimer();
            this.shift.status = this.Shift_Pause;

            // pause task if on
            if (this.is_task_started || this.is_task_resumed)
                await this.taskPause();

            try {
                const { data } = await axios.post(this.timer_shift_log_url, {
                    event: this.Shift_Pause,
                });

                this.shift.status = data?.action

                console.log('shiftPause', this.shift.status)

                this.sendEventToConversationEvents(this.Shift_Pause);
            } catch (e) {
                Utilities().showErrorSnackbar(e?.response?.data?.message);
            }
        },

        async shiftResume(item = null) {
            if (this.isForceUseAppForShiftStart()) {
                this.showWorkstationRequireDialog();
                return;
            }

            this.startShiftTimer();
            this.shift.status = this.Shift_Resume;

            try {
                const { data } = await axios.post(this.timer_shift_log_url, {
                    event: this.Shift_Resume,
                });

                this.shift.status = data?.action

                this.sendEventToConversationEvents(data?.action, item);
                
                if (this.is_task_started || this.is_task_resumed) {
                    this.startTaskTimer();
                } else {
                    this.stopShiftTimer();
                }

                this.restoreLastPauseTask();
                this.taskResume()
            } catch (e) {
                Utilities().showErrorSnackbar(e?.response?.data?.message);
            }
        },

        async shiftOut() {
            this.resetShiftTimer();
            this.shift.status = this.Shift_Out;
            try {
                await axios.post(this.timer_shift_log_url, {
                    event: this.Shift_Out,
                });
                this.sendEventToConversationEvents(this.Shift_Out);
            } catch (e) {
                Utilities().showErrorSnackbar(e?.response?.data?.message);
            }
        },

        startTaskTimer() {
            this.stopTaskTimer();
            if (this.is_shift_in || this.is_shift_resumed) {
                this.handle_task_timer_interval = setInterval(() => {
                    this.task.time.seconds += 1;
                    if (this.task.time.seconds === 60) {
                        this.task.time.seconds = 0;
                        this.task.time.minutes += 1;
                        if (this.task.time.minutes === 60) {
                            this.task.time.minutes = 0;
                            this.task.time.hours = this.task.time.hours + 1;
                        }
                        this.task.time.hours = Math.floor(
                            this.getTaskTimerDuration() / 60,
                        );
                    }
                }, 1000);
            } else {
                this.task.status = this.Task_Stop;
            }
        },

        taskTimerToMinutes(taskTime) {
            return taskTime.minutes + taskTime.hours * 60;
        },

        async syncTaskTimer() {
            if (!this.task?.id) {
                return
            }

            let shift_increment = false;
            let task_increment = false;

            if (this.is_shift_in || this.is_shift_resumed) {
                shift_increment = true;
            }

            if (this.is_task_started || this.is_task_resumed) {
                task_increment = true;
            }

            try {
                const { data } = await axios.post(this.timer_log_sync_url, {
                    item_id: this.task.id,
                    task_status: this.task.status,
                    shift_status: this.shift.status,
                    task_increment: task_increment,
                    shift_increment: shift_increment,
                });

                const result = data.data;
                const result_status = result?.task_status || this.is_task_stopped;
                if (
                    result?.shift_status != this.shift.status ||
                    result_status != this.task.status
                ) {
                    this.restoreShiftStatus();
                }

                if (
                    result?.shift_in_duration &&
                    this.isShiftReSyncRequired(result?.shift_in_duration)
                ) {
                    this.shift.duration = result?.shift_in_duration;
                    this.restoreShiftTimer();
                }

                if (
                    result?.task_duration &&
                    this.isTaskReSyncRequired(result?.task_duration)
                ) {
                    this.task.duration = result?.task_duration;
                    this.restoreTaskTimer();
                }

                this.updateCurrentConversationTaskTime();
            } catch (e) {
                console.trace(e);
                console.error("Failed to sync");
            }
        },

        stopTaskTimer() {
            clearInterval(this.handle_task_timer_interval);
        },

        resetTaskTimer() {
            this.task_time = {
                seconds: 0,
                minutes: 0,
                hours: 0,
            };
        },

        async taskStart() {
            if (
                this.isForceUseAppForShiftStart() &&
                (this.is_shift_paused || this.is_shift_out)
            ) {
                this.showWorkstationRequireDialog();
                return;
            }

            if (
                !this.task.status ||
                this.is_task_stopped ||
                this.is_task_paused
            ) {
                if (this.is_shift_paused || this.is_shift_out) {
                    await this.shiftIn();
                }

                this.startTaskTimer();
                let task_status = this.Task_Start;
                if (this.is_task_paused) {
                    let task_status = this.Task_Resume;
                }

                this.task.status = task_status;
                try {
                    const { data } = await axios.post(
                        this.timer_task_log_url + `/${this.task.id}`,
                        {
                            flag: task_status,
                        },
                    );
                    
                    this.task.status = data?.message?.action;

                    if (
                        data?.message?.action !== this.Task_Resume &&
                        data?.message?.action !== this.Task_Start
                    ) {
                        this.task.status = this.Task_Stop;
                        Utilities().showErrorSnackbar(
                            "Task is not started. Please refresh and try again",
                        );
                        return;
                    }

                    let time_duration =
                        data?.message?.assignment?.duration_in_minutes;

                    if (!time_duration) {
                        time_duration = 0;
                    }

                    this.task.duration = time_duration;
                    this.restoreTaskStatus();

                    useUserStore().userProfileSetActiveTask(
                        useUserStore().profile.id,
                        this.task.name,
                    );
                    this.sendEventToConversationEvents(this.Task_Start);
                } catch (e) {
                    Utilities().showErrorSnackbar(e?.response?.data?.message);
                }
            }
        },

        async taskPause() {
            if (this.is_task_started || this.is_task_resumed) {
                this.is_auto_paused = false;
                this.stopTaskTimer();
                this.task.status = this.Task_Pause;
                try {
                    await axios.post(
                        this.timer_task_log_url + `/${this.task.id}`,
                        {
                            flag: this.Task_Pause,
                        },
                    );
                    let taskDuration = this.taskTimerToMinutes(this.task.time);

                    if (
                        !this.space_paused_items.find(
                            (task) => task.id === this.task.id,
                        )
                    ) {
                        let pauseItem = {
                            id: this.task.id,
                            name: this.task.name,
                            duration_in_minutes: taskDuration,
                        };
                        this.space_paused_items.push(pauseItem);
                        this.space_paused_items.unshift(pauseItem);
                    }
                    this.sendEventToConversationEvents(this.Task_Pause);
                    useUserStore().userProfileSetActiveTask(
                        useUserStore().profile.id,
                        "",
                    );
                } catch (e) {
                    Utilities().showErrorSnackbar(e?.response?.data?.message);
                }
            }
        },

        async taskResume(task = null) {
            if (
                this.isForceUseAppForShiftStart() &&
                (this.is_shift_paused || this.is_shift_out)
            ) {
                this.showWorkstationRequireDialog();
                return;
            }

            if (task) {
                console.log("Selected task should be resumed...", task);
                let pauseItem = {
                    id: this.task.id,
                    name: this.task.name,
                    duration_in_minutes: 0,
                };
                this.space_paused_items.push(pauseItem);

                this.task.id = task.id;
                this.task.name = task.name;
            }

            if (this.is_task_stopped || this.is_task_started || this.is_task_resumed) {
                return false;
            }

            this.startTaskTimer();
            this.task.status = this.Task_Resume;

            if (!this.task.id) {
                return false;
            }
            
            try {
                const { data } = await axios.post(
                    this.timer_task_log_url + `/${this.task.id}`,
                    {
                        flag: this.Task_Resume,
                    },
                );
                    
                this.task.status = data?.message?.action;

                if (
                    data.message?.action !== this.Task_Resume &&
                    data.message?.action !== this.Task_Start
                ) {
                    this.task.status = this.Task_Stop;
                    Utilities().showErrorSnackbar(
                        "Task is not resumed. Please refresh and try again",
                    );
                    return;
                }

                let time_duration =
                    data.message?.assignment?.duration_in_minutes;

                if (!time_duration) {
                    time_duration = 0;
                }

                this.task.duration = time_duration;

                this.restoreTaskStatus();

                useUserStore().userProfileSetActiveTask(
                    useUserStore().profile.id,
                    this.task.name,
                );
                this.sendEventToConversationEvents(this.Task_Resume);
                this.space_paused_items = this.space_paused_items.filter(
                    (e) => e.id !== this.task.id,
                );
            } catch (e) {
                Utilities().showErrorSnackbar(e.response?.data?.message);
            }
        },

        async taskStop(task_id = null) {
            let actionTaskId = this.task.id;
            let activity = true;
            if (task_id && task_id != actionTaskId) {
                actionTaskId = task_id;
                activity = false;
            } else {
                this.stopTaskTimer();
                this.task.status = this.Task_Stop;
            }
            try {
                await axios.post(this.timer_task_log_url + `/${actionTaskId}`, {
                    flag: this.Task_Stop,
                    activity: activity,
                })
                
                this.resetTask()
                
                this.space_paused_items = this.space_paused_items.filter(
                    (e) => e.id !== actionTaskId,
                );

                useUserStore().userProfileSetActiveTask(
                    useUserStore().profile.id,
                    "",
                );
                this.sendEventToConversationEvents(this.Task_Stop);
                //this.restoreLastPauseTask();
            } catch (e) {
                Utilities().showErrorSnackbar(e?.response?.data?.message);
            }
        },

        isTaskStartedOnItem(taskId) {
            return (
                this.task.id == taskId &&
                (this.is_task_started || this.is_task_resumed)
            );
        },

        isShiftReSyncRequired(shift_in_duration) {
            const total_duration = this.getShiftTimerDuration();
            return (
                total_duration > shift_in_duration + 1 ||
                total_duration < shift_in_duration - 1
            );
        },

        isTaskReSyncRequired(task_duration) {
            const total_duration = this.getTaskTimerDuration();
            return (
                total_duration > task_duration + 1 ||
                total_duration < task_duration - 1
            );
        },

        isTaskPause(status) {
            return status === this.Task_Pause;
        },

        isTaskRunning(status) {
            return status === this.Task_Start || status === this.Task_Resume;
        },

        isShiftPause(status) {
            return status === this.Shift_Pause;
        },

        isShiftOut(status) {
            return status === this.Shift_Out;
        },

        isShiftRunning(status) {
            return status === this.Shift_In || status === this.Shift_Resume;
        },

        pausedTaskDuration(itemId) {
            const task = this.space_paused_items.find(
                (item) => item.id == itemId,
            );
            const duration_in_minutes = task?.duration_in_minutes;
            if (duration_in_minutes) {
                var hours = duration_in_minutes / 60;
                var rhours = Math.floor(hours);

                var minutes = (hours - rhours) * 60;
                var rminutes = Math.round(minutes);

                return rhours.pad() + ` : ` + rminutes.pad();
            } else {
                return `00 : 00`;
            }
        },

        isTaskPausedOnItem(taskId) {
            return this.space_paused_items.find((item) => item.id == taskId);
        },

        startOrResumeShift(item = null) {
            if (this.is_shift_in || this.is_shift_resumed) return;
            this.is_shift_paused ? this.shiftResume(item) : this.shiftIn(item);
        },

        onAppActive() {
            if (this.getActiveTimerStatus().includes(this.shift.status))
                this.syncTaskTimer();
        },

        restoreLastPauseTask() {
            this.is_auto_paused = false;
            if (
                this.space_paused_items.length === 0 ||
                this.task.status !== this.Task_Stop
            )
                return;

            const recent_pause_task = this.space_paused_items[0];
            this.task.id = recent_pause_task.id;
            this.task.name = recent_pause_task.name;

            this.task.duration = recent_pause_task.duration_in_minutes;
            this.task.status = this.Task_Pause;
            this.restoreTaskTimer();
            this.is_auto_paused = true;
        },

        onItemUpdate(item) {
            const task_item = this.space_paused_items?.find(
                (task_item) => task_item.id === item.id,
            );
            if (task_item) task_item.item_name = item.name;

            if (this.task?.id === item.id) this.task.name = item.name;
        },

        onWorkdayStatusChanged(profile_id, status) {
            this.restoreTimerOnWorkDayEnd(status);
        },

        startWorkday() {
            this.workday_status = this.Workday_Started;
            axios
                .post(this.workday_start_url)
                .then((result) => {
                    this.getShiftStatus();
                })
                .catch((error) => {
                    if (error?.response?.status === HttpStatusCodes.FORBIDDEN) {
                        this.workday_status = this.Workday_Stopped;
                    }
                    Utilities().showErrorSnackbar(error.response.data?.message);
                });
        },

        pauseWorkday() {
            return;
        },

        endWorkday() {
            this.workday_status = this.Workday_Stopped;
            axios.post(this.workday_end_url).catch((error) => {
                this.workday_status = this.Workday_Started;
            });
        },

        async restoreTimerOnEndAssignment(
            profile,
            action_performed_by,
            action,
        ) {
            //  if (action !== this.Task_Stop || profile?.id === action_performed_by || !useUserStore().isCurrentProfile(profile?.id)) return;

            if (!useUserStore().isCurrentProfile(profile?.id)) {
                return;
            }

            const old_task_name = this.task.name;
            const old_task_id = this.task.id;
            
            await this.getShiftStatus();
            await this.getPausedItems();

            if (
                old_task_id !== this.task_id &&
                profile?.id !== action_performed_by
            ) {
                const action_profile =
                    useUserStore().getUserProfile(action_performed_by);
                const action_user_name =
                    action_profile?.display_name || action_profile?.full_name;
                const message = `<b>${action_user_name}</b> has ended your task ${old_task_name}. Please select a new task.`;
                Utilities().showInfoSnackbar(message);
                window.notifyWith(action_user_name, "Task Stopped");
            }
        },

        async restoreTimerOnShiftActivity(user_profile_id, action) {
            if (!useUserStore().isCurrentProfile(user_profile_id)) {
                return;
            }

            if (action !== this.shift.status) {
                await this.getShiftStatus();
                await this.getPausedItems();
            }
        },

        async restoreTimerOnWorkDayEnd(status) {
            const is_task_running =
                this.is_task_started || this.is_task_resumed;
            this.workday_status = status;
            this.stopShiftTimer();
            
            await this.getShiftStatus();

            if (is_task_running && status === this.Workday_Stopped) {
                Utilities().showInfoSnackbar("Workday has ended.");
                window.notifyWith("Workday", "Workday has ended.");
            }
        },

        async getWorkLogs() {
            const response = await axios.get(this.timer_shift_work_log_url, {
                params: {
                    timezone: DateUtilities.getTimezone(),
                    date: new Date().toISOString().substring(0, 10),
                },
            });

            return response.data.data;
        },
        async sendEventToConversationEvents(event_type, item = null) {
            return;

            if (!event_type) return;

            if (
                !item &&
                this.getShiftEvents().includes(event_type) &&
                this.is_auto_paused
            ) {
                console.log(
                    "No active task so shift event will not send in conversation",
                );
                return;
            }

            if (!item) item = { id: this.task.id, name: this.task.name };

            const item_conversation = useConversation().getForItemId(item?.id);
            if (!item_conversation) return;

            await useConversation().openConversationForItem(item);
            await item_conversation?.sendTimerEventMessage(
                event_type,
                this.getEventMessage(event_type),
                this.getEventAction(event_type),
            );
        },

        getEventMessage(event_type) {
            const message = {
                [this.Shift_In]: "Shift Started",
                [this.Shift_Pause]: "Shift Paused",
                [this.Shift_Resume]: "Shift Resumed",
                [this.Shift_Out]: "Shift Stopped",
                [this.Task_Start]: "Task Started",
                [this.Task_Pause]: "Task Paused",
                [this.Task_Resume]: "Task Resumed",
                [this.Task_Stop]: "Task Stopped",
            };

            return message[event_type];
        },

        getEventAction(event_type) {
            const actions = {
                [this.Shift_In]: "Started",
                [this.Shift_Pause]: "Paused",
                [this.Shift_Resume]: "Resumed",
                [this.Shift_Out]: "Stopped",
                [this.Task_Start]: "Started",
                [this.Task_Pause]: "Paused",
                [this.Task_Resume]: "Resumed",
                [this.Task_Stop]: "Stopped",
            };

            return actions[event_type];
        },

        async getViewedTaskTimerDuration(item_id) {
            if (!item_id) return null;

            let duration = -1;

            try {
                const { data } = await axios.post(
                    `/user-profile-tasks/${item_id}/time-tracked`,
                    {
                        timezone: DateUtilities.getTimezone(),
                        date: DateUtilities.getStartAndEndDateFromToday(0),
                    },
                );

                duration = data.data;
            } catch (e) {
                console.log("Task duration not found");
            }

            this.setViewedTask({ id: item_id, duration: duration });

            return duration;
        },

        updateCurrentConversationTaskTime() {
            this.getViewedTaskTimerDuration(
                useConversation().active?.originalData?.item?.id,
            );
        },

        setViewedTask(val) {
            this.viewed_task = val;
        },

        isTaskOrShiftActive() {
            return (
                this.is_task_started ||
                this.is_task_resumed ||
                this.is_shift_in ||
                this.is_shift_resumed
            );
        },

        isForceUseAppForShiftStart() {
            return useUserStore().getCurrentUserProfile().is_force_app_shift;
        },
        showWorkstationRequireDialog() {
            this.ws_confirm_dialog = true;
        },
        hideWorkstationRequireDialog() {
            this.ws_confirm_dialog = false;
        },
        async setFutureShiftResume(time_string) {
            try {
                await axios.put("/user-profiles/shift-resume", {
                    timezone: DateUtilities.getTimezone(),
                    time: time_string,
                });
            } catch (error) {
                console.log("error setting future resume", error);
            }
        },

        resetTask() {
            this.task = {
                name: null,
                id: null,
                status: "Task_Stop",
                duration: 0,
                time: {
                    hours: 0,
                    seconds: 0,
                    minutes: 0,
                }
            }
        }
    },
});
