import axios from "axios";
import { ref, computed } from "vue"
import Conversation from "../Utilities/Conversation";
import LoggedInUser from "./logged-in-user";
const { loggedInUser,
        restoreSidePaenlState,
        updateUserCurrentConversationState,
    } = LoggedInUser()
import Cache from '../Utilities/Cache';
const cache = new Cache;
import {
    openSidePanel,
    closeSidePanel,
    setSidePanelCurrentViewWith,
    setSidePanelCurrentViewWithItems,
    sidePanelCurrentView
    } from './helpers'
import {
    findAndSetListItemForContextualConversation,
    resetListItemForContextualChat,
    createConversationForOpenedListItem,
    listItemNameOpenedForContextualChat,
    openItemToChat,
    openItemToEdit,
    resetContextualItem,
    setContextualItemById,
    openItemToChatAndEdit,
} from './list'
import BoardsApi from "./boards";
const { getItemById,
    getBoardById,
    getBoardAndItemByItemId,
    doesConversationBelongToItem,
    //openBoardById,
    //setItemsForChatAndEditorToRestoreAfterWorkspaceFetched,
    openBoard,
    addClientBoardToWorkspace,
    workspaceId
    } = BoardsApi()
const conversationList = ref([]);
const currentConversationIndex = ref(-1);
const hasConversationBeenOpened = ref(false);
// by default don't allow the user to open a conversation till state is restored from last session
const isConversationStateRestoringInProgress = ref(true);
const isCurrentConversationBeingPrepared = ref(false);
const tmpCurrentConversationId = ref(null);
const currentlyCreatingAconversationWithName = ref("");
const conversationDrafts = ref({});
const draftFromLastSession = ref(null)
const lastSessionActiveConvObjRef = ref(null)
const conversationHistory = ref([])

export default function conversationApi() {

    const currentConversation = computed(() => lastSessionActiveConvObjRef.value ?? conversationList.value[currentConversationIndex.value]);

    function getCurrentConversationObj() {
        return currentConversation.value
    }
    /* Conversation object has getter conversation_id  */
    function getCurrentConversationId() {
        return tmpCurrentConversationId.value ?? currentConversation.value?.conversation_id;
    }

    function getOpenedConversationIdInCurrentSession() {
        if( currentConversation.value?.isFake())
        return currentConversation.value?.id;
    }

    const getConversationObjIndex = (conversation_id) => {
        if (!conversationList.value)
            return -1;
        return conversationList
            .value
            .findIndex(conv => conv.getConversationId() == conversation_id)
    }

    const getConversationObjById = (conversation_id) => {
        const index = getConversationObjIndex(conversation_id);
        if (index == -1)
            return null
        return conversationList.value[index];
    }

    function restoringConverstionStateStarted() {
        isConversationStateRestoringInProgress.value = true
    }

    function restoringConverstionStateCompleted() {
        if( currentConversationIndex.value <= -1 && conversationList.value.length)
            setCurrentConversationIndex(0)
        isConversationStateRestoringInProgress.value = false

        if (!sidePanelCurrentView.value)
            closeSidePanel();
    }

    function isRestoringInProgress() {
        return false // || isConversationStateRestoringInProgress.value
    }
    /* it just opens conversations and doesn't send request chat state API request */
    function setConversationFlagWith(isOpen) {
        hasConversationBeenOpened.value = isOpen;
    }
    function openConversationOnly() {
        if (isRestoringInProgress())
            return;
        hasConversationBeenOpened.value = true;
        cache.conversationOpened();
    }
    function closeConversationOnly() {
        if (isRestoringInProgress())
            return;
        hasConversationBeenOpened.value = false;
        cache.conversationClosed();
    }

    function openConversation() {
        if (isRestoringInProgress())
            return;
        openConversationOnly()
        updateUserCurrentConversationState(true);
        cache.conversationOpened();
        //contexula open
    }

    function closeConversation() {
        if (isRestoringInProgress())
            return;
        closeConversationOnly();
        updateUserCurrentConversationState(false)
        cache.conversationClosed();
    }

    function isUserHittingThroughUniqueUrl() {
        const currentUrlQueryParams = new URLSearchParams(window.location.search);

        const itemId = currentUrlQueryParams.get('item-id');

        return itemId ? parseInt(itemId) : null;
    }

    function restoreConversationStateFromLastSession() {
        //console.log("restoringConversationStateFromLastSession -- start ")
        // explicit call is not reuqired as by default it's set to restoring in progress
        const userObj = loggedInUser()

        if (!userObj || !userObj.wasConversationOpenedInLastSession) {
            //console.log("User is not set or doesn't have proper method to check conversation state");
            return restoringConverstionStateCompleted()
        }

        const listItemIdOpeningThroughUrl = isUserHittingThroughUniqueUrl();
        //console.log(listItemIdOpeningThroughUrl, "opened through URL")
        let wasOpened = listItemIdOpeningThroughUrl ? true : userObj.wasConversationOpenedInLastSession();

        hasConversationBeenOpened.value = wasOpened;
        wasOpened ? cache.conversationOpened() : cache.conversationClosed();

        let itemIdForChat = listItemIdOpeningThroughUrl ? listItemIdOpeningThroughUrl :  userObj.listItemIdOpenedForChat();
        const conIdOpened = listItemIdOpeningThroughUrl ? null : userObj.conversationIdOpened();
        const convIndex = (listItemIdOpeningThroughUrl || !conIdOpened  ) ? -1 : getConversationObjIndex(conIdOpened);
        const itemIdForEditor = listItemIdOpeningThroughUrl ? null : userObj.listItemIdOpenedInEditor();
        const sidePanelOpenedWith =  userObj.sidePanelOpenedWith()
        const wasSidePanelOpenedWithEditor = userObj.isSidePanelOpenedWithEditor()
        //console.log( "from last state ", { itemIdForEditor, itemIdForChat})
        //console.log("Restroung last session with  ", { wasOpened, conIdOpened, convIndex, convIndex2: getConversationObjIndex(conIdOpened),  itemIdForChat, itemIdForEditor, sidePanelOpenedWith} )
        // no list item opened and no conversation(might be removed or wrong state or at system start up state)
        if (itemIdForChat == null && itemIdForEditor == null  && convIndex < 0) {
            //console.log("No Conversation created or No list ITem opened");
            return restoringConverstionStateCompleted()
        }
        /*  */
        let itemForChat = getItemById(itemIdForChat);
        //console.log(" State restore settings ", userObj.settings)
        //console.log(" con opened & index: ", conIdOpened, convIndex, "side panel opened with ",  sidePanelOpenedWith, " item for Chat ", itemIdForChat, " item for Editor ", itemIdForEditor);
        if (!listItemIdOpeningThroughUrl) {
            const convObj = conversationList.value[convIndex];
            const itemForEditor  = itemIdForChat == itemIdForEditor ? itemForChat : getItemById(itemIdForEditor);
            //console.log("found Itmes for Chat & Editor : ", itemForChat, itemForEditor)
            // only conversation
            // chat (dm/ group) + item editor
            // contextual conversation
            // item chat + item for editor
            let conversationBelongsToItem = doesConversationBelongToItem(convObj?.id, itemIdForChat);
            if (convObj?.isContextual() && (!itemForChat || conversationBelongsToItem)) {
                //console.log("Item being loaded for Conversation ", conversationBelongsToItem)
                itemForChat = getItemById(convObj.itemId);
                conversationBelongsToItem = true;
            }
            //console.log("setting Contextual Item .. ", itemIdForChat, convObj?.isContextual() , convObj?.itemId)
            if( itemForChat ) {
                openItemToChat(itemForChat)
            }

            //console.log("setting  Item for Edting",  itemForChat?.list_item_name)
            if(itemForEditor) {
                openItemToEdit(itemForEditor)
            }
            if( convObj ) {
                //console.log("setting Up Conversation ", convObj?.isContextual(), " conversationBelongsToItem", conversationBelongsToItem)
                setCurrentConversationAt(convIndex);
                if( !conversationBelongsToItem)
                    resetListItemForContextualChat();
            }

            if( wasSidePanelOpenedWithEditor &&  !itemIdForEditor )
                setSidePanelCurrentViewWith('');
            else
                setSidePanelCurrentViewWith(sidePanelOpenedWith);

            wasOpened && openConversation();

            /* to restore item for chat and edit as restoring API reques to fetch workspace details was not completed*/

            /* setItemsForChatAndEditorToRestoreAfterWorkspaceFetched(
                        !itemForChat ? itemIdForChat : null,
                        !itemForEditor ? itemIdForEditor : null
                    ) */

            if( sidePanelOpenedWith || itemForEditor )
                openSidePanel();

            //console.log("final state", { itemForEditor, itemForChat})
            /* console.log("Final round ",
                { itemNameopenedForchat: itemForChat?.list_item_name,
                    itemOpenedForEditor: itemForEditor?.list_item_name,
                    currentView: getSidePanelCurrentview(),
                    wasOpened: hasConversationBeenOpened.value
                }) */
        }else {
            const { item, board } = getBoardAndItemByItemId(itemIdForChat);
            //console.log("Item opened through url ", itemIdForChat, itemForChat);
            if(!board || !item)
                return console.log("Opening item through url sharing : item/board not found") //setItemsForChatAndEditorToRestoreAfterWorkspaceFetched(itemIdForChat, itemIdForChat)

            openBoard(board)
            openItemToChatAndEdit(item)
            openSidePanel();
        }
        //console.log("restoreFromLastSession Before: ", currentConversationIndex.value)
        if( currentConversationIndex.value <= -1 )
            setCurrentConversationIndex(0);

        //console.log("restoreFromLastSession after : ", currentConversationIndex.value)
        // don't call open/closeConversation methods
        restoringConverstionStateCompleted();
    }

    function prepareConversations(list) {
        let conHandler;
        let dontShowConnectingIndicator = false;

        list
            ?.find((conversation, index) => {
            if (conversation.conversation_id == lastSessionActiveConvObjRef.value?.id)
                dontShowConnectingIndicator = true;
            return dontShowConnectingIndicator;
        })

        list.forEach((conversation, index) => {
            tmpCurrentConversationId.value = null;
            if (index == 0)
                tmpCurrentConversationId.value = conversation.conversation_id;

            //set conversation with cached messages if it was active in last session
            if( conversation.conversation_id == lastSessionActiveConvObjRef.value?.id) {
                conHandler = lastSessionActiveConvObjRef.value;
                conHandler.setConversationVariables(conversation);
                setCurrentConversationIndex(index);
                lastSessionActiveConvObjRef.value = null
                conHandler?.initialize(dontShowConnectingIndicator)
            }else {
                conHandler = new Conversation(conversation, false)
                conHandler?.initialize(dontShowConnectingIndicator)
            }
            conversationList.value.push(conHandler);

        })

        //console.log("prepareConversations : ", currentConversationIndex.value)

        lastSessionActiveConvObjRef.value = null;

        /* conversationList
            .value
            .forEach(conHandler => {
                conHandler?.initialize();
        }) */

    }

    async function loadMyConversations() {
        const data = await axios.get('/conversations')
        if (!data.res)
            data.res = []
        prepareConversations(data.res);
        restoreConversationStateFromLastSession();
    }

    function loadMyConversationsWithList(convList) {
        prepareConversations(convList);
        restoreConversationStateFromLastSession();
    }

    async function fetchConversationById(conversation_id) {
        const {data} = await axios.get(`/conversations/${conversation_id}`);
        return data;
    }

    async function fetchConversationBySID(conversation_sid) {
        const { data } = await axios.get(`/conversations/sid/${conversation_sid}`);
        return data;
    }

    async function updateConversationLastMessageSentAt(conversation_id, { are_buffered_messages_sent = false, message}) {
        const index = getConversationObjIndex(conversation_id);
        const conversationObj = conversationList.value[index]
        if (!conversationObj)
            return console.log("Invalid Conversation");

        const { data: res } = await axios.post(`/conversations/${conversation_id}/message-sent`, { are_buffered_messages_sent, message });
        if (!res)
            return;
        conversationObj.updateLastMessageSentAt(res.last_message_sent_at);
        // here itself move this conversation to top
        //conversationList.value.splice(index,1);
        //conversationList.value.unshift(conversationObj);
        //currentConversationIndex.value = 0
    }

    function moveConversationToTopAtIndex(index) {
        const conversationToMove = conversationList.value.splice(index, 1)[0];
        conversationList.value.unshift(conversationToMove);
    }

    function updateConversationListOrderingWhenConversationReceivedAMessage(conversation_id, isSetAsCurrent= false) {
        //console.log("updating Ordering of Conv list -start ", conversation_id, isSetAsCurrent);
        const indexToMove = getConversationObjIndex(conversation_id);
        //console.log(" Conversation found ", conversation_id, isSetAsCurrent);
        if (indexToMove == -1)
            return console.log("Invalid conversation to move to top");
        if (indexToMove == 0 )
            return; //console.log("updating Ordering of Conv list -end -alredy at the top"); //setCurrentConversationAt(0); //console.log("message on top conversation");
        //console.log(" need to move conversation to top");
        const conversationToMove = conversationList.value.splice(indexToMove, 1)[0];
        conversationList.value.unshift(conversationToMove);
        //console.log("setting up ...moving to top at ", indexToMove);
        isSetAsCurrent
            ? setCurrentConversationAt(0)
            : updateCurrentConversationIndexAfterAddingOrMovingConversation(indexToMove);
    }
    function updateCurrentConversationIndexAfterAddingOrMovingConversation(indexBeingMoved) {
        //console.log("updating currentConversationIndex from ",  currentConversationIndex.value)
        /* if conversation was alredy before currentConversationIndex, don't update  */
        if (indexBeingMoved < currentConversationIndex.value)
            return;

        // if this is only conversation don't do anything
        if (conversationList.value.length == 1)
            return; //console.log(" only 1 Conversation");
        /* if message received on currently opened conversation itself them move to top */
        if (indexBeingMoved == currentConversationIndex.value)
            return currentConversationIndex.value = 0;

        // if alredy there is conversation alredy opened, update currentConversationIndex as now index is incresed by 1
        currentConversationIndex.value = currentConversationIndex.value + 1;
        //console.log("after updating currentConversationIndex ", currentConversationIndex.value)
    }

    async function loadTokenForConversation(conversation) {
        if (!conversation) {
            const { data: res } = await axios.post(`/access-token`)
            return res;
        }

        const { data: res } = await axios.post(`/conversations/${conversation.conversation_id}/token`)
        return res;
    }

    function setChannelForConversation(index, conversationObj) {
        conversationList.value[index].channel = conversationObj
    }

    function setCurrentConversationById(id) {
        if (conversationList.value.length == 1)
            return;

        const index =  getConversationObjIndex(id);

        /* if not found(-1) or if it's alredy in first place( 0 ), then simply return */
        if (index == -1 || index == 0)
            return;

        setCurrentConversationAt(index);

        /* const conObjToMoveToTop = conversationList.value.splice(index, 1)[0];
        if (conObjToMoveToTop)
            conversationList.value.unshift(conObjToMoveToTop); */
    }

    function getFakeConversationIndex() {
        return conversationList.value.findIndex(conObj => conObj.isFake())
    }

    function setFakeCurrentConversation(conversation_name) {
        const indexFakeAt = getFakeConversationIndex();
        /* keep deleting fake conversations */
        if( indexFakeAt >= 0 ) {
            updateFakeConversationWith(conversation_name, indexFakeAt);
            if( indexFakeAt > 0 )
                moveConversationToTopAtIndex(indexFakeAt);
            setCurrentConversationAt(indexFakeAt);
            return;
        }
        createFakeConversationAndSetAsCurrentConversation(conversation_name, "item");
    }

    function createFakeConversationAndSetAsCurrentConversation(conversation_name, conversation_type) {
        const conObj = new Conversation({ conversation_name, conversation_id: Date.now(), conversation_type });
        conObj.setAsFakeConversation();
        conversationList.value.unshift(conObj);
        setCurrentConversationAt(0);
        return conObj;
    }

    function removeFakeConversation() {
        const indexFakeAt = getFakeConversationIndex();
        if (indexFakeAt >= 0)
            conversationList.value.splice(indexFakeAt, 1);
    }

    function updateFakeConversationWith(newName, indexFakeConversationAt) {
        let conHandler = conversationList.value[indexFakeConversationAt];
        conHandler.updateNameAndId(newName, Date.now());
    }

    function updateFakeConversationNameWith(newName) {
        const indexFakeAt = getFakeConversationIndex();
        updateFakeConversationWith(newName, indexFakeAt);
    }

    function updateConversationNameWith(conversation_id, newName) {
        const conObj = getConversationObjById(conversation_id);
        conObj && (conObj.name = newName);
    }

    function removeConversationById(conversation_id) {
        const index = getConversationObjIndex(conversation_id);
        if (index >= 0)
            conversationList.value.splice(index, 1);
    }

    function setConversationWithIdAsCurrent(conversation_id, fake_conversation_name = '') {
        closeConversationOnly();

        if (conversation_id)
            setCurrentConversationById(conversation_id);
        else
            setFakeCurrentConversation(fake_conversation_name);

        openConversation();
    }

    function setConversationForItemAsCurrent(item) {
        closeConversationOnly();
        const index = conversationList.value.findIndex( conObj => conObj.itemId == item.list_item_id);
        //console.log("setConversationForItemAsCurrent ", item.list_item_id, item.list_item_name, index, conversationList.value[index]);
        if( index === -1 )
            setFakeCurrentConversation(item.list_item_name)
        else
            setCurrentConversationAt(index);
        openConversation()
    }

    function setContextualConversation(conversation_id, conversation_name = "") {
        closeConversation();
        if (conversation_id) {
            setCurrentConversationById(conversation_id);
        } else {
            setFakeCurrentConversation(conversation_name);
        }
        openConversation();
    }

    function setCurrentConversationIndex(index) {
        currentConversationIndex.value = index;
    }

    function setCurrentConversationAt(index) {
        if( index < 0 || index >= conversationList.value.length )
            return console.log("setCurrentConversationIndex, invalid index")
        //console.log("setCurrentConversationAt : before ", conversationHistory.value.length)
        //console.trace("from where this called")
        const conObj = conversationList.value[index];
        //console.log("next conv : ", conObj.getName());
        if( conObj.isCurrentConversation() )
            return conObj.markMessagesAsRead();

        //notify other participants user status for current as Absent
        const currentConvObj = conversationList.value[currentConversationIndex.value]
        currentConvObj?.notifyAsAbsent();
        //console.log("current conv : ", currentConvObj.getName());
        //reset contextual item if any item set previously
        setCurrentConversationIndex(index)

        conObj.forceUiUpdate();
        conObj.notifyAsActive(); //notify as this becomes active
        //console.log("setCurrentConversationAt: middle ", conversationHistory.value.length)
        !isRestoringInProgress()
            && updateConversationHistory(currentConvObj)
        //console.log("setCurrentConversationAt: after ", conversationHistory.value.length);
        return conObj;
    }

    function updateConversationHistory(convObj) {
        //console.log("updateConversationHistory : start ", convObj.getName());
        if( !convObj )
            return;
        const latestHistoryItem = conversationHistory.value[conversationHistory.value.length - 1];
        if( latestHistoryItem  &&
                (
                    ( latestHistoryItem.conv_id && latestHistoryItem.conv_id == convObj.id)
                        ||
                    (latestHistoryItem.item_id && latestHistoryItem.item_id == convObj.itemId)
                )
            ) {
                return console.log("not adding to history ");
        }
        const newItem = { conv_id: convObj.id,  conv_name: convObj.getName(), item_id: convObj.itemId }
        conversationHistory.value.push(newItem)
        //console.log("updateConversationHistory : end added : ", conversationHistory.value.length);
    }

    function goBackToPreviousConversation() {
        const previousConv = conversationHistory.value[conversationHistory.value.length - 1];

        //console.log("goBackToPreviousConversation going to : ",  {...previousConv})
        if(previousConv.item_id) {
            const item = getItemById(previousConv.item_id)
            item && openItemToChat(item)
        }else if( previousConv.conv_id ) {
            setCurrentConversationById([previousConv.conv_id])
        }
        //console.log(" goBackToPreviousConversation : after restore ",  [...conversationHistory.value])
        conversationHistory.value.pop();
    }

    function selectConversationAsCurrentById(id) {

        const index = getConversationObjIndex(id);

        /* if not found(-1) or if it's alredy in first place( 0 ), then simply return */
        if (index == -1 || index == currentConversationIndex.value)
            return;

        selectConversationAsCurrentAt(index);
    }

    function selectConversationAsCurrentAt(index) {
        //reset contextual item if any item set previously
        resetContextualItem();
        const conObj =  setCurrentConversationAt(index);
        conObj?.notifyAsActive();
        conObj?.isContextual() && setContextualItemById(conObj?.itemId);
    }

    function setConversationAtIndexAsCurrentConversation(index) {
        const convObj = conversationList.value.splice(index, 1)[0];
        conversationList.value.unshift(convObj);
        setCurrentConversationAt(0);
        // if this current conversation not contextual, then set last_opened_item = null
        if (convObj.isContextual())
            findAndSetListItemForContextualConversation(convObj.conversation_id);
        else
            resetListItemForContextualChat(null);

        openConversation(); // to update the current conversation state in current session
    }

    function sendMessageOnLatestConversation(message) {
        if (!conversationList.value.length)
            return alert("You don't have any conversations");
        const conObj = conversationList.value[0]
        conObj.sendMessage(message);
        openConversation();
    }

    function sendMediaOnLatestConversation(fileOrBlob) {
        if (!conversationList.value.length)
            return alert("You don't have any conversations");
        const conObj = conversationList.value[0]
        conObj.sendMultiMediaMessage(fileOrBlob);
        openConversation();
    }

    async function createTwilioConversationForConversation(convObj) {
        const { data: conversation } = await axios.post(`/conversations/${convObj.getConversationId()}/twilio`);
        return conversation;
    }

    function setCurrentCreatingConversationNameWith(name) {
        currentlyCreatingAconversationWithName.value = name;
    }

    async function createConversation(data, isToAddToConversationList = false) {
        /*
        {
            name: '',
            type: ''dm',
            participant_user_ids: []
        }
        */
        /* CAUTION: here we don't have name of conversation for dm type  */
        setCurrentCreatingConversationNameWith(data.name)
        const { data: newConv } = await axios.post("/conversations", data);
        if (!isToAddToConversationList) {
            setCurrentCreatingConversationNameWith("");
            return newConv;
        }
        const conHandler = new Conversation(newConv)
        conversationList.value.unshift(conHandler);
        conHandler.forceUiUpdate();
        setConversationAtIndexAsCurrentConversation(0);
        setCurrentCreatingConversationNameWith("");
    }

    async function createGroupConversation(data) {
        /*
        {
            name: '',
            type: 'group',
            participant_user_ids: []
        }
        */
        setCurrentCreatingConversationNameWith(data.name)

        const conObj = createFakeConversationAndSetAsCurrentConversation(data.name, "group")
        conObj.showLoadingIndicator();

        const { data: newConv } = await axios.post("/conversations", data);
        conObj.resetWithConversation(newConv);
        setCurrentCreatingConversationNameWith("");
    }

    async function inviteContactsOnCurrentConversation(participantDetails) {
        const convObj = getCurrentConversationObj()
        if (convObj.hasConversationNotCreated() && convObj.isContextual()) {
            setCurrentCreatingConversationNameWith(listItemNameOpenedForContextualChat());
            convObj.showLoadingIndicator();
            const conversation = await createConversationForOpenedListItem(participantDetails.participant_user_ids);
            convObj.resetWithConversation(conversation);
            setCurrentCreatingConversationNameWith("");
            return;
        }
        if (convObj.isDm()) {
            //participantDetails.type = "group";
            participantDetails.participant_user_ids = [...convObj.getParticipantIds(), ...participantDetails.participant_user_ids]
            return createConversation(participantDetails);
        }

        const data = {
            participant_user_ids: participantDetails.participant_user_ids
        }
        const { data: conversation } = await axios.post(`/conversations/${convObj.getConversationId()}/invite`, data)

        convObj.resetAfterContactsInvited(conversation);

        /* const conHandler = new Conversation(conversation)
        conversationList.value.unshift(conHandler);
        setCurrentConversationAt(0); */
    }

    function updateCurrentConversationState(isInPogress) {
        isCurrentConversationBeingPrepared.value = isInPogress
    }

    function showConnectingIndicator() {
        updateCurrentConversationState(true);
    }

    function hideConnectingIndicator() {
        updateCurrentConversationState(false)
    }

    function invitationReceivedOnConversation(invitedConversationDetail) {

        let index = getConversationObjIndex(invitedConversationDetail.conversation_id);
        if (index == -1) {
            const conObj = new Conversation(invitedConversationDetail)
            conversationList.value.push(conObj);
            index = conversationList.value.length - 1;
        } else {
            const conHandler = conversationList.value[index];
            if (conHandler.sid &&
                conHandler.hasConversationNotCreated())
                conHandler.resetWithConversation(invitedConversationDetail);
        }

        setConversationAtIndexAsCurrentConversation(index);
    }

    function didICreateTheConversation(twilioConversation) {
        return currentlyCreatingAconversationWithName.value
                && currentlyCreatingAconversationWithName.value == twilioConversation.friendlyName

        //return cache.getTwilioIdentity() == twilioConversation.createdBy
    }

    async function newInvitationReceivedOnConversation(twilioConversation) {
        if( didICreateTheConversation(twilioConversation))
            return console.log("returning not adding...");
        const  newConv  = await fetchConversationBySID(twilioConversation.sid);
        addNewlyCreatedConversationAtTop(newConv, twilioConversation);
        //const index = conversationList.value.length - 1;
        //setConversationAtIndexAsCurrentConversation(index);
    }
    /**
     *
     * @param {*} newConv new conversation details
     * @returns
     */
    function addNewlyCreatedConversationAtTop(newConv, twilioConversation) {
        if (!newConv.conversation_id)
            return;
        let conHandler = getConversationObjById(newConv.conversation_id);
        //if alredy added,then don't add puplicate
        if (conHandler)
            return;
        conHandler = new Conversation(newConv, false)
        conHandler.onConversationFound(twilioConversation);
        conversationList.value.unshift(conHandler);

        updateCurrentConversationIndexAfterAddingOrMovingConversation()
    }

    function markAllConversationMessagesAsRead() {
        conversationList
            .value
            .forEach(conHandler => {
                conHandler?.markMessagesAsRead();
            })
    }

    function makeCurrentConversationAsAbsent() {
        getCurrentConversationObj()?.notifyAsAbsent();
    }

    function makeCurrentConversationAsInActive() {
        getCurrentConversationObj()?.notifyAsInActive();
    }

    function makeCurrentConversationAsActive() {
        getCurrentConversationObj()?.notifyAsActive();
    }

    function getItemIdIfCurrentConversationIsContextual() {
        return getCurrentConversationObj()?.itemId;
    }

    function restoreConversationDrafts() {
        const drafts = cache.getConversationDrafts();
        conversationDrafts.value = {};
        if(!drafts || typeof drafts !== 'object')
            return;

        /* remove all conv those have empty drafts */
        for (const convSid in drafts) {
            const message = drafts[convSid]
            if(message)
                conversationDrafts.value[convSid] = message;
        }

        initializeLastSessionDraftIfAny();
    }

    function updateCurrentConversationDraft(message) {
        const conv = getCurrentConversationObj()
        const convSid = conv?.sid

        if( !convSid )
            return;
        //if message is empty then delete
        if( !message )
            delete conversationDrafts.value[convSid]
        else{
            conversationDrafts.value[convSid] = message;
        }

        //cache.setConversationDrafts(conversationDrafts.value)
    }

    function cacheCurrentConversation() {
        const conv = getCurrentConversationObj()
        if ( !conv || !conv.sid)
            return;

        cache.setActiveConversation(conv.originalDetails())
    }

    function getDraftForConversation(convSid) {
        return convSid ? conversationDrafts.value[convSid] : '';
    }

    function initializeLastSessionDraftIfAny() {
        const conv = cache.getActiveConversation();
        const convSid = conv?.conversation_sid;
        if(!convSid )
            return;

        conv.messages = conv.messages
                            ?.map( message => {
                                message.dateCreated = new Date(message.dateCreated)
                                return message;
                            })

        if( conv.messages?.length ) {
            hideConnectingIndicator();
            const conHandler = new Conversation(conv, false);
            conHandler.setCachedMessages(conv.messages)
            conHandler.initialize(true)
            lastSessionActiveConvObjRef.value = conHandler
        }

        draftFromLastSession.value = getDraftForConversation(convSid);
        //console.log("draftFromLastSession", draftFromLastSession.value);
    }

    function clearLastSessionDraft() {
        draftFromLastSession.value = '';
    }

    function cacheConversationDrafts() {
        getCurrentConversationObj()?.notifyAsAbsent();
        cacheCurrentConversation()
        cache.setConversationDrafts(conversationDrafts.value)
    }

    async function loadClientConversationAndBoardDetails(twilioConversation) {

        const { data } = await axios.get(`/client-details/${twilioConversation.sid}`);
        addNewlyCreatedConversationAtTop(data?.conversation, twilioConversation)
        addClientBoardToWorkspace(data?.board);
        //const index = conversationList.value.length - 1;
        //setConversationAtIndexAsCurrentConversation(index);
    }

    async function openConversationWhereParticicipantEmail(user_email) {
        if(!user_email) {
            console.log("Invalid email id to open Conversation ", user_email)
            return false;
        }
        const index = conversationList.value
            .findIndex(conv => {
                const participant = conv.getDMParticipant();
                //console.log(" participant: " + conv.getName() , participant);
                return participant?.user_email == user_email
            })
        if (index < 0) {
            console.log("openConversationWithEmail .. invalid", index, user_email)
            return false
        }
        setCurrentConversationAt(index);
        return true;
    }

    function openBoardUserConversationAndSharePaymentLinkForQuote(quote) {
        const board = getBoardById(quote.fk_board_id);
        if (!board?.board_id)
            return console.log("board not found");
        const isConvFound = openConversationWhereParticicipantEmail(board.board_category);

        isConvFound && currentConversation.value.sendPaymentLink(quote);
    }

    return {
        loadMyConversations,
        loadMyConversationsWithList,
        currentConversationIndex: computed(() => currentConversationIndex.value),
        fetchConversationById,
        //getChatAccessToken,
        setConversationFlagWith,
        openConversationOnly,
        closeConversationOnly,
        openConversation,
        closeConversation,
        isConversationOpened: computed(() => hasConversationBeenOpened.value),
        isChatPanelOpened: () => hasConversationBeenOpened.value,
        hasConversationBeenOpened: computed(() => hasConversationBeenOpened.value),
        isRestoringInProgress,

        setContextualConversation,
        setConversationWithIdAsCurrent,
        setConversationForItemAsCurrent,
        setCurrentConversationAt,
        selectConversationAsCurrentById,
        selectConversationAsCurrentAt,
        setConversationAtIndexAsCurrentConversation,
        updateConversationListOrderingWhenConversationReceivedAMessage,
        setCurrentConversationById,
        setFakeCurrentConversation,
        updateConversationNameWith,
        updateFakeConversationNameWith,
        removeFakeConversation,
        removeConversationById,

        loadTokenForConversation,
        sendMessageOnLatestConversation,
        sendMediaOnLatestConversation,
        updateConversationLastMessageSentAt,

        setChannelForConversation,
        conversationList: computed(() => conversationList.value),
        conversations: computed(() => conversationList.value),
        currentConversation: computed(() => currentConversation.value),
        getCurrentConversationId,
        getCurrentConversationObj,
        getItemIdIfCurrentConversationIsContextual,
        makeCurrentConversationAsAbsent,
        makeCurrentConversationAsActive,
        makeCurrentConversationAsInActive,
        //isChannelLoadingForCurrentConversation: computed(() =>  )
        createConversation,
        createGroupConversation,
        createTwilioConversationForConversation,
        inviteContactsOnCurrentConversation,
        updateCurrentConversationState,
        showConnectingIndicator,
        hideConnectingIndicator,
        isCurrentConversationBeingPrepared: computed(() => isCurrentConversationBeingPrepared.value),

        invitationReceivedOnConversation,
        newInvitationReceivedOnConversation,

        markAllConversationMessagesAsRead,

        draftFromLastSession: computed(() => draftFromLastSession.value),
        initializeLastSessionDraftIfAny,
        clearLastSessionDraft,
        restoreConversationDrafts,
        cacheConversationDrafts,
        updateCurrentConversationDraft,
        getDraftForConversation,
        cacheCurrentConversation,

        conversationHistory: computed(() => conversationHistory.value),
        goBackToPreviousConversation,

        loadClientConversationAndBoardDetails,
        openConversationWhereParticicipantEmail,
        openBoardUserConversationAndSharePaymentLinkForQuote,
    }
}
